:root {
  --primary-hover-red: #f33501;
}

/************************ CONTACTS COMPONENT *********************************/
.contacts {
    background: var(--primary-light-belge);
    padding: 2rem 0;
    color: black;
  }
  
  .contacts h1 {
    padding: 1.3rem 0;
  }
  
  .contacts p {
    font-size: 1.2rem;
  }

  /*------------------ INPUTS -------------------------------*/
.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-light-black);
  border-radius: 0;
  color: var(--primary-light-black);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .formcontainer {
  padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--primary-light-black);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-light-orange);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-white);
  background: var(--primary-hover-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--primary-light-green);
}

/*---------------- MEDIA -----------------------*/
@media (max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.4rem;
  }
}