/*---------------- Appointment Availability -----------------------*/
.availability {
    margin-bottom: 0.625rem;
    background: var(--primary-light-belge);
    text-align: center;
  }
  
  .availability h1 {
    color: var(--primary-dark);
  }
  
  .availability button {
    border-radius: 12px;
    padding: 0 0.4rem;
    background: var(--primary-light-grey);
    color: black;
    border: none;
  }
  
  .availability.active button {
    border-radius: 12px;
    padding: 0 0.4rem;
    background: var(--primary-light-blue);
    color: black;
    border: none;
  }