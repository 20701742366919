/*---------------- Calendar -----------------------*/
.calendar {
    padding-bottom: 0.625rem;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .available {
    background: var(--primary-light-blue);
    border-radius: 50%;
  }