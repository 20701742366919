/************************ NAVBAR *********************************/
:root {
  --primary-title-orange: #f68920;
  --primary-title-red: #ee3425;
  --primary-title-green: #6cbe45;
}

.header {
  background-color: #000;
  color: black;
  text-shadow: none !important;
}

.navbar-nav .nav-link {
  color: var(--primary-white) !important;
  text-shadow: none !important;
}

.navbar .nav-link, .navbar .navbar-brand {
  text-shadow: none !important;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
  color: var(--primary-title-green) !important;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: var(--primary-title-green) !important;
}

.navbar-toggler-icon {
  color: var(--primary-white) !important;
}

.sticky-nav {
  position: sticky;
  top: 0;
}
  
.title.tropical {
  color: var(--primary-title-orange);
}

.title.zest {
  color: var(--primary-title-green);
}

.title.nutrition {
  color: var(--primary-title-red);
}