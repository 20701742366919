/************************ FREE CALL *********************************/
.freecall {
  padding: 2rem 0;
  text-align: center;
  color: var(--primary-green);
}

.freecallFill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.freecallFill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
