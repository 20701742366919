/************************ SOCIAL FOLLOW ****************************/
.social-container {
  background: var(--primary-white);
  padding: 25px 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.social-container h2 {
  padding: 25px 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: lowercase;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.linkedin {
  color: #0077b5;
}

a.instagram {
  color: black;
}

.social-container img {
  width: 16rem;
  height: 16rem;
  border-radius: 10%;
  padding: 0.3rem;
  /* border: 1px solid var(--primary-black); */
}
