/************************ FOOTER COMPONENT *********************************/
.footer {
    background: var(--primary-light-black);
    color: var(--primary-white);
    padding: 3rem;
  }
  
  .footer a {
    text-decoration: none;
    color: var(--primary-white);
  }
  
  .footer a:hover {
    text-decoration: none;
    color: var(--primary-orange);
    cursor: pointer;
  }
  
  .footer p {
    margin-bottom: 0;
    color: var(--primary-white);
  }