/************** BANNER ***************/
.banner-wrapper {
  /* background-color: var(--primary-light-beige); */
  padding-bottom: 2rem;
}

.banner-wrapper h1 {
  color: var(--primary-light-green);
  font-size: 3.5rem;
}

.banner-img {
  height: 20rem;
  width: 20rem;
}

.btn {
  margin-right: 0.5rem !important;
}
