/************************ EXPERIENCES *********************************/
.experience-right li {
    text-align: left;
    margin-left: 7rem;
    /* font-size: 0.9rem; */
}

.experience-right h1 {
  text-align: left;
  margin-left: 7rem;
  /* font-size: 0.9rem; */
}

.experience h1 {
  text-align: left;
  /* font-size: 0.9rem; */
}

@media (max-width: 468px) {
  .experience-right li {
    text-align: left;
    margin-left: 0rem;
    /* font-size: 0.9rem; */
}

.experience-right h1 {
  text-align: left;
  margin-left: 0rem;
  /* font-size: 0.9rem; */
}
}