@import url('https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans&display=swap');
/**/
:root {
  /* --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f68920;
  --primary-dark: rgb(52, 58, 64);
  --primary-red: #ee3425;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0, 0, 0, 0.7);
  --primary-title-green: #6cbe45;
  --primary-green: #295227;
  --primary-light-belge: #fff2e1;
  --primary-light-blue: #73abff;
  --primary-blue: #00008B ; */

  --primary-white: #ffffff;
  --primary-light-beige: #fff2e1;
  --primary-light-green: #21421f;
  --primary-light-black: #000000;
  --primary-light-orange: #f68920;
  --primary-light-blue: #73abff;
}

main {
  min-height: 80vh;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
  font-family: 'Lato', sans-serif;
  text-transform: capitalize;
  color: var(--primary-light-orange);
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
  font-family: 'Lato', sans-serif;
  text-transform: capitalize;
  color: var(--primary-light-green);
}

h3 {
  padding: 1rem 0;
  font-size: 1.2rem;
  font-family: 'Lato', sans-serif;
  text-transform: capitalize;
  color: var(--primary-light-black);
}

h5 {
  font-size: 1rem;
  padding: 0.5rem 0;
  font-family: 'Lato', sans-serif;
  text-transform: capitalize;
  color: var(--primary-light-black);
}

p {
  text-align: justify;
  color: var(--primary-light-black);
}

li {
  color: var(--primary-light-black);
}

/******************************* TEXT EDITOR  **********************************************/
.rdw-editor-main {
  min-height: 300px;
  border: 1px solid var(--primary-light-grey);
}
