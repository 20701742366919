/************************ ABOUT ME *********************************/
.about {
  padding-top: 0rem !important;
  padding-bottom: 0rem;
  padding-left: 0rem !important;
  background-color: #fff2de; /*this your primary color*/
  color: black;
}

.about-heading {
  text-transform: none;
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 400px;
  height: 600px;
  border-radius: 10%;
  padding: 0.3rem;
  border: 1px solid var(--primary-orange);
}

@media (max-width: 468px) {
  .about {
    padding-left: 1rem !important;
  }

  .profile-img {
    width: 300px;
    height: 500px;
  }
}
